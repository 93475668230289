<template>
  <div class="secondary-market">
    <div class="container-xl">
      <paginated-list :filter="filter"
                      :count="items.length"
                      :total-count="totalCount"
                      :data-loading="dataLoading"
                      @update="loadData"
      >
        <template v-slot:filter>
          <list-filter :filter="filter"/>
        </template>
        <template v-slot:loading>
          <div class="card">
            <div class="card-body">
              <i>Загрузка...</i>
            </div>
          </div>
        </template>
        <template v-slot:no-items>
          <div class="card">
            <div class="card-body">
              <i>Нет доступных предложений</i>
            </div>
          </div>
        </template>
        <div class="card mb-3" v-for="item in items">
          <div class="card-body">
            <div class="row">
              <div class="col-name">
                <div class="h6">
                  <router-link :to="{ name: 'secondaryMarketOffer', params: { uuid: item.uuid } }" class="project-link">
                    {{ item.project.name }}
                  </router-link>
                </div>
                  <div>
                      <div class="small text-muted p-1">ID {{ item.project.paymentCode }} / {{item.project.type === ProjectType.PROJECT ? 'Проект' : 'Займ'}}
                      </div>
                  </div>
                  <div v-if="lendlyInvest(item)" class="d-inline align-content-center">
                      <span class="small secondary-market text-success border-success border rounded p-1">Предложение Лендли Инвест
                        <c-tooltip text='Аффилированный к Лендли инвестор "Лендли Инвест" распродаёт на вторичном рынке остаток займа, несобранный инвесторами на первичном рынке.' />
                      </span>
                  </div>
                <div v-if="item.hasCurrentOverduePayments" class="d-inline align-content-center">
                      <span class="small secondary-market border-warning border text-warning rounded p-1">Есть просроченные платежи</span>
                </div>
                <div v-else-if="item.hasOverduePayments">
                  <span class="small secondary-market border-secondary border rounded p-1">Были просроченные платежи</span>
                </div>
              </div>
              <div class="col-investment-money">
                <div class="small text-muted">Размер предложения</div>
                <div class="d-inline-block">
                  <money :money="item.investmentMoney"/>
                  <c-tooltip class="ml-2" text="Можно инвестировать частями"/>
                </div>
              </div>
              <div class="col-price">
                <div class="small text-muted">Цена</div>
                <div>
                  <money :money="item.price"/>
                </div>
              </div>
              <div class="col-interest-rate">
                <div class="small text-muted">
                    {{item.project.interestRateType === InterestRateType.FIXED ? 'Ставка по договору' : 'Прогнозная ставка'}}
                </div>
                <div>{{ item.project.interestRate }}%</div>
              </div>
              <div class="col-left-months">
                <div class="small text-muted">Оставшийся срок</div>
                <TimeLeftField :left-days="item.leftDays" :left-months="item.leftMonths"/>
              </div>
              <div class="col-ltv">
                <div class="small text-muted">LTV</div>
                <div>{{ item.ltv ? (Math.round(item.ltv * 10) / 10) + "%" : "-" }}</div>
              </div>
              <div class="col-profit">
                <div class="small text-muted">Доходность</div>
                <div class="profit_and_tooltip">
                  <div class='profit_number' v-if="commissions[item.project.uuid]">
                    {{ Math.round((item.profit - item.project.interestRate * commissions[item.project.uuid] / 100) * 10) / 10 }}%
                  </div>
                  <div class='profit_number' v-else>
                    {{ Math.round((item.profit) * 10) / 10 }}%
                  </div>
                    <c-tooltip v-if="item.project.interestRateType === InterestRateType.FIXED" class="ml-2" text="Итоговая доходность за вычетом комиссии платформы"/>
                    <c-tooltip v-if="item.project.interestRateType === InterestRateType.VARIABLE" class="ml-2" text="Итоговая доходность зависит от результатов проекта"/>
                </div>
              </div>
              <div class="col-actions">
                <router-link :to="{ name: 'secondaryMarketOffer', params: { uuid: item.uuid } }"
                             class="btn btn-success btn-sm">
                  Инвестировать
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </paginated-list>
    </div>
    <processing :show="processing"/>
  </div>
</template>

<script>
import {Money, PaginatedList, Processing, TimeLeftField, CTooltip} from "@/components/common";
import {createFeeSettingsProvider, createOfferProvider} from "@/utils/di";
import ListFilter from "./ListFilter";
import {TransactionType} from "@/utils/api-client";
import {ProjectType} from "@/utils/api-client/enums/ProjectType";
import {InterestRateType} from "@/utils/api-client/enums/InterestRateType";
import {appConfig} from "../../../../../configs";

export default {
  name: "SecondaryMarket",
    computed: {
        InterestRateType() {
            return InterestRateType
        },
        ProjectType() {
            return ProjectType
        }
    },
  components: {
    ListFilter,
    Money,
    PaginatedList,
    Processing,
    TimeLeftField,
    CTooltip
  },
  data() {
    return {
      processing: false,
      items: [],
      totalCount: 0,
      dataLoading: false,
      filter: {},
      commissions: []
    };
  },
  methods: {
    async loadData(page, perPage, {loanAmountFrom, loanAmountTo, ...filter}) {
      this.items = [];
      this.dataLoading = true;

      try {
        const provider = await createOfferProvider();
        const collection = await provider.getListSelling(
            false,
            {
              ...filter,
              loanAmountFrom: loanAmountFrom && loanAmountFrom * 100,
              loanAmountTo: loanAmountTo && loanAmountTo * 100
            },
            page,
            perPage
        );
        this.items = collection.items;
        this.totalCount = collection.totalCount;
        this.commissions = await this.calculateDividendFee(collection)
      } finally {
        this.dataLoading = false;
      }
    },

    async feeSettingsCreate(projectUUID) {
      const feeSettingsProvider = await createFeeSettingsProvider();
      return (await feeSettingsProvider.getList({projectUuids: [projectUUID]}));
    },
      lendlyInvest(item) {
          return appConfig.lendlyInvestUserUuid === item.sellerUuid;
      },

    async calculateDividendFee(collection) {
      let commissionsForEachProject = []
      let feeSettings = null
      for (const secondaryInvestment of collection.items) {
        feeSettings = await this.feeSettingsCreate(secondaryInvestment.project.uuid)
        let fee = null;
        if (feeSettings) {
          feeSettings.items.map(item => {
            if (item.transactionType === TransactionType.DIVIDEND_INTEREST_FEE) {
              fee = item.percent
            }
          })
        }
        if (fee) {
          commissionsForEachProject[secondaryInvestment.project.uuid] = fee
        }
      }
      return commissionsForEachProject;
    },
  }
}
</script>

<style lang="scss">
.secondary-market {
  .card .card-body {
    padding: 0.5rem 1.5rem;
  }

  .col-name,
  .col-investment-money,
  .col-price,
  .col-interest-rate,
  .col-left-months,
  .col-ltv,
  .col-profit,
  .col-actions {
    padding: 1%;
  }

  .col-investment-money,
  .col-price,
  .col-interest-rate,
  .col-left-months,
  .col-ltv,
  .col-profit,
  .col-actions {
    text-align: center;
  }

  .col-investment-money,
  .col-price,
  .col-interest-rate,
  .col-left-months,
  .col-ltv,
  .col-profit {
    position: relative;
  }

  .col-investment-money:before,
  .col-price:before,
  .col-interest-rate:before,
  .col-left-months:before,
  .col-ltv:before,
  .col-profit:before {
    content: "";
    background: #ecedef;
    position: absolute;
    bottom: 25%;
    left: 0;
    height: 50%;
    width: 0.125rem;
  }

  .ml-2 {
    display: inline-block;
  }

  .profit_number {
    display: inline-block;
  }

  .col-investment-money:before,
  .col-left-months:before {
    background: none;
  }

  .col-name {
    width: 100%;
  }

  .col-investment-money {
    width: 34%;
  }

  .col-price {
    width: 33%;
  }

  .col-interest-rate {
    width: 33%;
  }

  .col-left-months {
    width: 34%;
  }

  .col-ltv {
    width: 33%;
  }

  .col-profit {
    width: 33%;
  }

  .col-actions {
    width: 100%;
  }

  @media (min-width: 768px) { // Bootstrap MD
    .col-left-months:before {
      background: #ecedef;
    }

    .col-name {
      width: 100%;
    }

    .col-investment-money {
      width: 18%;
    }

    .col-price {
      width: 18%;
    }

    .col-interest-rate {
      width: 17%;
    }

    .col-left-months {
      width: 17%;
    }

    .col-ltv {
      width: 14%;
    }

    .col-profit {
      width: 16%;
    }

    .col-actions {
      width: 100%;
    }
  }

  @media (min-width: 992px) { // Bootstrap LG
    .col-investment-money:before {
      background: #ecedef;
    }

    .col-name {
      width: 26%;
    }

    .col-investment-money {
      width: 13%;
    }

    .col-price {
      width: 10%;
    }

    .col-interest-rate {
      width: 12%;
    }

    .col-left-months {
      width: 11%;
    }

    .col-ltv {
      width: 8%;
    }

    .col-profit {
      width: 8%;
    }

    .col-actions {
      width: 12%;
      padding-top: 1.5rem;
    }
  }
}
</style>
